import { Injectable } from '@angular/core';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import {
	HttpEvent, 
	HttpInterceptor, 
	HttpHandler, 
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { catchError,map} from 'rxjs/operators';
import { Location } from '@angular/common'; //接收route para
import swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpService } from '../services/http.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TokenService } from '../services/token.service';

@Injectable()

export class RequestInterceptor implements HttpInterceptor {


	constructor(
		public httpService: HttpService, 
		private logger: NGXLogger,
		private spinner: NgxSpinnerService,
		private translate: TranslateService,
		private tokenService: TokenService
	) { }


	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

		//this.logger.debug('request interceptor');
		
		//開始loading動畫
		this.spinner.show();
		this.httpService.requestCount++;

		//從cookie取得token
		//const token = this.cookieService.getCookie('Authorization');
		const token = this.tokenService.getToken();
		
		//判斷是否為內部系統API
		let reg = RegExp(environment.APIUrlReg);

		//非內部系統API 直接傳送request 
		if(!reg.test(req.url)){
			this.logger.info({
				name: 'request info',
				description: '呼教非系統API 不塞入token',
				request: req
			});
			return next.handle(req);

		//內部系統API request加入token再傳送
		}else{
			const newRequest = req.clone({ setHeaders: {'Authorization': `Bearer ${token}`, 'Language': this.translate.currentLang}});
			this.logger.info({
				name: 'request info',
				request: newRequest
			});
			return next.handle(newRequest);
		}

		

    	
	}

}