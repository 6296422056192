import { PermissionCode } from 'src/app/admin/models/permission.model';

let defaultHidden = true;
let defaultExpand = false;

export const Menus: any = [
    {
        title: 'home',
        icon: 'home-outline',
        data: 'home',
        link: '/',
        hidden: defaultHidden,
    },

    {
        title: 'shop',
        icon: 'home-outline',
        data: 'home-shop',
        link: '/shop/select',
        hidden: defaultHidden,
    },

    //行銷活動管理
    {
        title: 'discount',
        icon: 'shopping-cart-outline',
        data: PermissionCode.homeDiscount.code,
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            // {
            //     title: 'shopDiscountList',
            //     data: 'home-discount-shopDiscountList',
            //     link: '/shop/shop-discount',
            //     icon: 'pricetags-outline',
            //     hidden: defaultHidden,
            // },
            {
                // 活動並行管理
                title: 'productDiscountParallel',
                data: PermissionCode.homeDiscount.productDiscountParallel.code,
                link: '/discount/discount-event-parallel-rule-mgmt/edit/1',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            //for demo only
            // {
            //   title: 'productDiscountCalculator',
            //   data: 'home-discount-productCalculator',
            //   link: '/discount/calculator-mgmt',
            //   icon: 'pricetags-outline',
            //   hidden: defaultHidden,
            // },
            {
                // 行銷專案管理
                title: 'productDiscountGroup',
                data: PermissionCode.homeDiscount.productDiscountGroup.code,
                link: '/discount/discount-event-group-mgmt',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'productDiscountList',
                data: 'home-discount-productDiscountGroup-productDiscountList',
                link: '/shop/product-discount',
                queryParams: { discount_event_group_id: 2 },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            /*
                {
                    title: 'discountExcludeList',
                    data: 'home-discount-discountExcludeList',
                    link: '/shop/discount-exclude',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'discountCode',
                    data: 'home-discount-discountCode',
                    link: '/shop/coupon/code',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'marketingPageList',
                    data: 'home-discount-marketingPageList',
                    link: '/content/marketing',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },*/
            {
                // 貼紙兌換活動
                title: 'redeemList',
                data: PermissionCode.homeDiscount.redeem.list,
                link: '/shop/redeem',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //一般配送商品
    {
        title: 'product',
        icon: 'shopping-bag-outline',
        data: 'home-product',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            /*
				{
                    title: 'brandList',
                    icon: 'grid-outline',
                    data: 'home-product-brandList',
                    link: '/shop/brand',
                    hidden: defaultHidden,
                },
				*/
            {
                title: 'productCategory',
                icon: 'grid-outline',
                data: 'home-product-productCategory',
                link: '/shop/category',
                hidden: defaultHidden,
            },
            {
                title: 'productList',
                data: 'home-product-productList',
                link: '/shop/product',
                queryParams: { sort_field: 'created_at', sort_type: 'desc' },
                icon: 'shopping-bag-outline',
                hidden: defaultHidden,
            },
            {
                // 門市紅利點數兌換商品
                title: 'productExchangeList',
                data: PermissionCode.homeProduct.productExchange.list,
                link: '/shop/product-exchange',
                queryParams: { sort_field: 'created_at', sort_type: 'desc' },
                icon: 'shopping-bag-outline',
                hidden: defaultHidden,
            },
            {
                title: 'pointProductCategory',
                icon: 'grid-outline',
                data: 'home-product-pointProductCategory',
                link: '/shop/point-category',
                hidden: defaultHidden,
            },
            /*
                {
                    title: 'sku',
                    data: 'home-product-sku',
                    link: '/shop/sku',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'productFilterList',
                    data: 'home-product-productFilterList',
                    link: '/shop/filter',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                */
        ],
    },

    //訂閱商品
    {
        title: 'sproduct',
        icon: 'shopping-bag-outline',
        data: 'home-sproduct',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'sproductCategory',
                icon: 'grid-outline',
                data: 'home-sproduct-sproductCategory',
                link: '/shop/s-category',
                hidden: defaultHidden,
            },
            {
                title: 'sproductList',
                data: 'home-sproduct-sproductList',
                link: '/shop/s-product',
                queryParams: { sort_field: 'created_at', sort_type: 'desc' },
                icon: 'shopping-bag-outline',
                hidden: defaultHidden,
            },
        ],
    },

    // 系列商品
    {
        title: 'seriesProduct',
        icon: 'shopping-bag-outline',
        data: PermissionCode.homeSeriesProduct.code,
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'seriesProductGroup',
                data: PermissionCode.homeSeriesProduct.seriesProductGroup.code,
                link: '/shop/series-product-group',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //ERP商品管理
    {
        title: 'erpProduct',
        icon: 'shopping-bag-outline',
        data: 'home-erpProduct',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'exProductGroupCategory',
                data: 'home-erpProduct-exProductGroupCategory',
                link: '/content/ex_product_group/category',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'exGroupList',
                data: 'home-erpProduct-exGroupList',
                link: '/shop/ex-group',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'exProductList',
                data: 'home-erpProduct-exProductList',
                link: '/shop/ex-product',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'erpProductList',
                data: 'home-erpProduct-erpProductList',
                link: '/shop/erp-product',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //訂單
    {
        title: 'order',
        icon: 'file-text-outline',
        data: 'home-order',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'paymentList',
                data: 'home-order-paymentList',
                link: '/shop/payment',
                hidden: defaultHidden,
            },

            {
                title: 'pickReport',
                data: 'home-order-pickReport',
                link: '/shop/pick-report',
                hidden: defaultHidden,
            },
            {
                title: 'shipReport',
                data: 'home-order-shipReport',
                link: '/shop/ship-report',
                hidden: defaultHidden,
            },

            {
                title: 'subscriptionList',
                data: 'home-order-subscriptionList',
                link: '/shop/subscription',
                //icon: 'pricetags-outline',
                queryParams: { sort_field: 'created_at', sort_type: 'desc' },
                hidden: defaultHidden,
            },
            {
                title: 'orderParentList',
                data: 'home-order-orderParentList',
                link: '/shop/order-parent',
                //icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderAllList',
                data: 'home-order-orderList',
                link: '/shop/order',
                //icon: 'pricetags-outline',
                hidden: defaultHidden,
            },

            //外部商城
            {
                title: 'orderBookList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'book' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderMomoList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'momo' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderGoodList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'good' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderEtmallList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'etmall' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderPchomeList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'pchome' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderYahooList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'yahoo' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },

            //官網
            {
                title: 'orderDefaultList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'default' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderStoreList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'store' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'orderSubscriptionList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'subscription' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            // 門市銷貨單
            {
                title: 'posOrderSold',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'pos_order_sold' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            // 門市宅配訂單
            {
                title: 'posOrder',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { source: 'pos_order' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },

            //出貨單
            {
                title: 'orderShippingList',
                data: 'home-order-orderList',
                link: '/shop/order',
                queryParams: { if_process_number: '1' },
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },

            /*
                {
                    title: 'exOrderList',
                    data: 'home-order-exOrderList',
                    link: '/ex-order/order',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },



                {
                    title: 'shippingList',
                    data: 'home-order-shippingList',
                    link: '/shop/shipping',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },




                {
                    title: 'returnOption',
                    data: 'home-order-returnOption',
                    link: '/content/option/return',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                {
                    title: 'cancelOption',
                    data: 'home-order-cancelOption',
                    link: '/content/option/cancel',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },

                {
                    title: 'shippingCompanyList',
                    data: 'home-order-shippingCompanyList',
                    link: '/shop/shipping-company',
                    icon: 'pricetags-outline',
                    hidden: defaultHidden,
                },
                */
        ],
    },

    //金流
    {
        title: 'payment',
        icon: 'layout-outline',
        data: 'home-payment',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'paymentList',
                data: 'home-payment-paymentList',
                link: '/shop/payment',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //物流
    {
        title: 'logistic',
        icon: 'layout-outline',
        data: 'home-logistic',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'shippingHolidayList',
                data: 'home-logistic-shippingHolidayList',
                link: '/content/holiday/shipping-date',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'logisticList',
                data: 'home-logistic-logisticList',
                link: '/shop/logistic',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
            {
                title: 'tcatCallList',
                data: 'home-logistic-tcatCallList',
                link: '/shop/tcat_call',
                icon: 'pricetags-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //文章
    {
        title: 'content',
        icon: 'layout-outline',
        data: 'home-content',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            //最新消息
            {
                title: 'newsList',
                data: 'home-content-newsList',
                link: '/content/news',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //活動訊息
            {
                title: 'activityList',
                data: 'home-content-activityList',
                link: '/content/activity',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //媒體報導
            {
                title: 'mediaList',
                data: 'home-content-mediaList',
                link: '/content/media',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //馬修日記
            {
                title: 'diaryList',
                data: 'home-content-diaryList',
                link: '/content/diary',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //認識產品
            {
                title: 'introductionList',
                data: 'home-content-introductionList',
                link: '/content/introduction',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //生活提案
            {
                title: 'articleCategory',
                icon: 'grid-outline',
                data: 'home-content-articleCategory',
                link: '/content/article/category',
                hidden: defaultHidden,
            },
            {
                title: 'articleList',
                icon: 'message-square-outline',
                data: 'home-content-articleList',
                link: '/content/article',
                hidden: defaultHidden,
            },
            //產地直人
            {
                title: 'reviewCategory',
                data: 'home-content-reviewCategory',
                link: '/content/review/category',
                icon: 'grid-outline',
                hidden: defaultHidden,
            },
            {
                title: 'reviewList',
                data: 'home-content-reviewList',
                link: '/content/review',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
            //食譜
            {
                title: 'recipeList',
                data: 'home-content-recipeList',
                link: '/content/recipe',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //門市管理
    {
        title: 'store',
        icon: 'layout-outline',
        data: 'home-store',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'storePage',
                icon: 'image-outline',
                data: 'home-store-storePage',
                link: '/content/store/page/store',
                hidden: defaultHidden,
            },
            {
                title: 'storeCategoryList',
                icon: 'image-outline',
                data: 'home-store-storeCategoryList',
                link: '/content/store/category',
                hidden: defaultHidden,
            },
            {
                title: 'storeList',
                data: 'home-store-storeList',
                link: '/content/store',
                icon: 'message-square-outline',
                hidden: defaultHidden,
            },
        ],
    },

    //客服管理
    {
        title: 'customerService',
        icon: 'layout-outline',
        data: 'home-customerService',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            /*
            	{
                    title: 'contactCategory',
                    icon: 'browser-outline',
                    data: 'home-customerService-contactCategory',
                    link: '/content/contact-category',
                    hidden: defaultHidden,
                },
                {
                    title: 'contactList',
                    icon: 'browser-outline',
                    data: 'home-customerService-contactList',
                    link: '/content/contact',
                    hidden: defaultHidden,
                },


                //購物常見問題
                {
                    title: 'qaCategory',
                    icon: 'image-outline',
                    data: 'home-customerService-qaCategory',
                    link: '/content/qa/category',
                    hidden: defaultHidden,
                },
                {
                    title: 'qaList',
                    data: 'home-customerService-qaList',
                    link: '/content/qa',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                },

                //站內信
                {
                    title: 'mailList',
                    data: 'home-customerService-mailList',
                    link: '/member/mail',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                },*/

            {
                title: 'smsList',
                data: 'home-customerService-smsList',
                link: '/member/sms',
                icon: 'message-square-outline',
                queryParams: { sort_field: 'created_at', sort_type: 'desc' },
                hidden: defaultHidden,
            },
        ],
    },

    //固定克制頁面們
    {
        title: 'fixedPage',
        icon: 'layout-outline',
        data: 'home-fixedPage',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'pageList',
                icon: 'browser-outline',
                data: 'home-fixedPage-pageList',
                link: '/content/page/fixed',
                hidden: defaultHidden,
            },
        ],
    },

    //公司管理
    /*
        {
            title: 'about',
            icon: 'layout-outline',
            data : 'home-about',
            expanded: defaultExpand,
            hidden: defaultHidden,
            children: [
                {
                    title: 'pageList',
                    icon: 'browser-outline',
                    data: 'home-about-pageList',
                    link: '/content/about',
                    hidden: defaultHidden,
                },

                {
                    title: 'mediaCategory',
                    icon: 'browser-outline',
                    data: 'home-about-mediaCategory',
                    link: '/content/media/category',
                    hidden: defaultHidden,
                },

                {
                    title: 'mediaList',
                    data: 'home-about-mediaCategory-mediaList',
                    link: '/content/media',
                    icon: 'message-square-outline',
                    hidden: defaultHidden,
                }

            ],
        },*/

    //廣告
    {
        title: 'recommend',
        icon: 'layout-outline',
        data: 'home-recommend',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'recommendHome',
                icon: 'image-outline',
                data: 'home-recommend-recommendHome',
                link: '/content/recommend/home',
                hidden: defaultHidden,
            },

            /*
             	{
                    title: 'recommendParameter',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendParameter',
                    link: '/admin/parameter_home',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendCover',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendCover',
                    link: '/content/recommend/cover',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendHeader',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendHeader',
                    link: '/content/recommend/header',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendSpecial',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendSpecial',
                    link: '/content/recommend/special',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendStartBanner',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendStartBanner',
                    link: '/content/recommend/star-banner',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendBottom',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendBottom',
                    link: '/content/recommend/bottom',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendFooter',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendFooter',
                    link: '/content/recommend/footer',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendLeftCover',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendLeftCover',
                    link: '/content/recommend/left_cover',
                    hidden: defaultHidden,
                },
                {
                    title: 'recommendRightCover',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendRightCover',
                    link: '/content/recommend/right_cover',
                    hidden: defaultHidden,
                },
			*/
            {
                title: 'productHomeEdit',
                icon: 'image-outline',
                data: 'home-recommend-productHomeEdit',
                link: '/content/product-home-edit',
                hidden: defaultHidden,
            },
            //banner
            {
                title: 'bannerProductHome',
                icon: 'image-outline',
                data: 'home-recommend-bannerProductHome',
                link: '/content/banner/product-home',
                hidden: defaultHidden,
            },
            // {
            //     title: 'bannerSubscription',
            //     icon: 'image-outline',
            //     data: 'home-recommend-bannerSubscription',
            //     link: '/content/banner/subscription',
            //     hidden: defaultHidden,
            // },
            // {
            //     title: 'bannerPurchase',
            //     icon: 'image-outline',
            //     data: 'home-recommend-bannerPurchase',
            //     link: '/content/banner/purchase',
            //     hidden: defaultHidden,
            // },

            //link
            /*
                {
                    title: 'homeLink',
                    icon: 'browser-outline',
                    data: 'home-recommend-homeLink',
                    link: '/content/homelink/top',
                    hidden: defaultHidden,
                },
                {
                    title: 'keywordLink',
                    icon: 'browser-outline',
                    data: 'home-recommend-keywordLink',
                    link: '/content/keyword/hot-keyword',
                    hidden: defaultHidden,
                },*/
            /*
                {
                    title: 'footer',
                    icon: 'browser-outline',
                    data: 'home-recommend-footer',
                    link: '/content/footer/f-about',
                    hidden: defaultHidden,
                },
                */
            {
                title: 'live',
                icon: 'browser-outline',
                data: 'home-recommend-live',
                link: '/content/live/live',
                hidden: defaultHidden,
            },
            /*
                {
                    title: 'recommendMarquee',
                    icon: 'image-outline',
                    data: 'home-recommend-recommendMarquee',
                    link: '/content/recommend/marquee',
                    hidden: defaultHidden,
                },

                {
                    title: 'newProduct',
                    icon: 'browser-outline',
                    data: 'home-recommend-newProduct',
                    link: '/shop/recommend/product/new',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendTopic',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendTopic',
                    link: '/content/recommend/group/topic',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendVideo',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendVideo',
                    link: '/content/recommend/group/video',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendStar',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendStar',
                    link: '/content/recommend/star',
                    hidden: defaultHidden,
                },

                {
                    title: 'recommendClassic',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendClassic',
                    link: '/content/recommend/classic',
                    hidden: defaultHidden,
                },*/

            {
                title: 'frame',
                icon: 'browser-outline',
                data: 'home-recommend-frame',
                link: '/content/frame',
                hidden: defaultHidden,
            },
            /*
                {
                    title: 'recommendDiscountEventMenuBanner',
                    icon: 'browser-outline',
                    data: 'home-recommend-recommendDiscountEventMenuBanner',
                    link: '/content/recommend/discount_event_menu_banner',
                    hidden: defaultHidden,
                },*/
        ],
    },

    //會員
    {
        title: 'member',
        icon: 'person-outline',
        data: 'home-member',
        hidden: defaultHidden,
        expanded: defaultExpand,
        children: [
            {
                title: 'memberQuickSearch',
                icon: 'search',
                data: PermissionCode.homeMember.memberQuickSearch.code,
                link: '/member/search',
                hidden: defaultHidden,
            },
            {
                title: 'memberOccupation',
                icon: 'list',
                data: 'home-member-memberOccupation',
                link: '/content/option/occupation',
                hidden: defaultHidden,
            },
            {
                title: 'memberList',
                icon: 'list',
                data: 'home-member-memberList',
                link: '/member',
                hidden: defaultHidden,
            },
            {
                title: 'memberTagList',
                icon: 'list',
                data: 'home-member-memberTagList',
                link: '/member/tag',
                hidden: defaultHidden,
            },
            {
                title: 'memberCRMTagList',
                icon: 'list',
                data: 'home-member-memberCRMTagList',
                link: '/member/crm-tag',
                hidden: defaultHidden,
            },

            {
                title: 'memberLevelList',
                icon: 'list',
                data: 'home-member-memberLevelList',
                link: '/member/level',
                hidden: defaultHidden,
            },

            /*
                {
                    title: 'consumptionList',
                    icon: 'list',
                    data: 'home-member-consumptionList',
                    link: '/member/consumption',
                    hidden: defaultHidden,
                },
                {
                    title: 'deliveryList',
                    icon: 'list',
                    data: 'home-member-deliveryList',
                    link: '/shop/delivery',
                    hidden: defaultHidden,
                },
                {
                    title: 'epaperList',
                    icon: 'list',
                    data: 'home-member-epaperList',
                    link: '/member/epaper',
                    hidden: defaultHidden,
                },*/
        ],
    },

    //會員優惠
    {
        title: 'account',
        icon: 'person-outline',
        data: 'home-account',
        hidden: defaultHidden,
        expanded: defaultExpand,
        children: [
            {
                title: 'accountList',
                icon: 'list',
                data: 'home-account-accountList',
                link: '/member/account',
                hidden: defaultHidden,
            },

            {
                title: 'dollbaoFeedback',
                icon: 'list',
                data: 'home-account-dollbaoFeedback',
                link: '/member/account-feedback/dollbao',
                hidden: defaultHidden,
            },
            {
                title: 'dollbaoTransfer',
                icon: 'list',
                data: 'home-account-dollbaoTransfer',
                link: '/member/account-transfer/dollbao',
                hidden: defaultHidden,
            },
            {
                title: 'shopcashFeedback',
                icon: 'list',
                data: 'home-account-shopcashFeedback',
                link: '/member/account-feedback/shopcash',
                hidden: defaultHidden,
            },
            {
                // 紅利點數兌換設定
                title: 'shopcashQualifyRule',
                icon: 'list',
                data: PermissionCode.homeAccount.shopcashQualifyRule.code,
                link: '/member/account-feedback/shopcash-qualify-rule',
                hidden: defaultHidden,
            },
            {
                title: 'shopcashTransfer',
                icon: 'list',
                data: 'home-account-shopcashTransfer',
                link: '/member/account-transfer/shopcash',
                hidden: defaultHidden,
            },

            {
                title: 'couponFeedback',
                icon: 'list',
                data: 'home-account-couponFeedback',
                link: '/member/account-feedback/coupon',
                hidden: defaultHidden,
            },

            {
                title: 'couponTicketList',
                icon: 'list',
                data: 'home-account-couponTicketList',
                link: '/shop/coupon/ticket',
                hidden: defaultHidden,
            },

            {
                title: 'systexTicketList',
                icon: 'list',
                data: 'home-account-systexTicketList',
                link: '/shop/systex-coupon/ticket',
                hidden: defaultHidden,
            },
            {
                title: 'couponTransfer',
                icon: 'list',
                data: 'home-account-couponTransfer',
                link: '/member/coupon-transfer',
                hidden: defaultHidden,
            },
            {
                //* 優惠券批次匯入
                title: 'couponTransferImport',
                icon: 'list',
                data: 'home-account-couponTransferImport',
                link: '/member/coupon-transfer-import',
                hidden: defaultHidden,
            },
            {
                // 電子票券訂單
                title: 'couponOrderList',
                icon: 'list',
                data: PermissionCode.homeAccount.couponOrderList.code,
                link: '/shop/coupon-order',
                hidden: defaultHidden,
            },
            {
                // 儲值金加值訂單
                title: 'dsystexOrderList',
                icon: 'list',
                data: PermissionCode.homeAccount.dsystexOrderList.code,
                link: '/shop/dsystex-order',
                hidden: defaultHidden,
            },
        ],
    },

    //系統管理
    {
        title: 'admin',
        icon: 'settings-outline',
        data: 'home-admin',
        expanded: defaultExpand,
        hidden: defaultHidden,
        children: [
            {
                title: 'adminList',
                icon: 'list',
                data: 'home-admin-adminList',
                link: '/admin/user',
                hidden: defaultHidden,
            },
            {
                title: 'adminGroup',
                icon: 'person-delete-outline',
                data: 'home-admin-adminGroup',
                link: '/admin/group',
                hidden: defaultHidden,
            },
            {
                title: 'importLogList',
                icon: 'activity-outline',
                data: 'home-admin-importLogList',
                link: '/admin/import/log',
                hidden: defaultHidden,
            },
            {
                title: 'logList',
                icon: 'activity-outline',
                data: 'home-admin-logList',
                link: '/admin/log',
                hidden: defaultHidden,
            },
            {
                title: 'qrcodeLogList',
                icon: 'activity-outline',
                data: 'home-admin-logList',
                link: '/admin/qrcode_log',
                hidden: defaultHidden,
            },
            {
                title: 'apiLogList',
                icon: 'activity-outline',
                data: 'home-admin-apiLogList',
                link: '/admin/apilog',
                hidden: defaultHidden,
            },
            /*
                {
                    title: 'parameterList',
                    icon: 'activity-outline',
                    data: 'home-admin-parameterList',
                    link: '/admin/parameter',
                    hidden: defaultHidden,
                },*/
        ],
    },
];
