export const Permission = [

	//行銷活動管理
	{
		name: 'discount_event',
		code: 'home-discount',
		children: [
			/*
			{
				name: 'discountCode',
				actions: ['select', 'create', 'update', 'delete']
			},*/
			{
				name: 'shopDiscount',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'shopDiscountItem',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				// 活動並行管理
				name: 'productDiscountParallel',
				actions: ['select', 'update']
			},
			{
				// 行銷專案管理
				name: 'productDiscountGroup',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'productDiscount',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'productDiscountItem',
				actions: ['select', 'create', 'update', 'delete']
			},
			/*
			{
				name: 'discountExclude',
				actions: ['select', 'create', 'delete']
			},
			{
				name: 'marketingPage',
				actions: ['select', 'create', 'update', 'delete']
			},*/
			{
				// 貼紙兌換活動
				name: 'redeem',
				actions: ['select', 'create', 'update', 'delete']
			}
		]
	},


	//商品管理模組
	{
		name: 'product',
		code : 'home-product',
		children: [
			{
				// 門市紅利點數兌換商品
				name: 'productExchange',
				actions: ['select', 'create', 'update', 'delete', 'copy']
			},
			{
				name: 'productCategory',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'productCategoryItem',
				actions: ['select', 'create', 'delete']
			},
			/*
			{
				name: 'productFilter',
				actions: ['select', 'create', 'update', 'delete']
			},*/
			{
				name: 'product',
				actions: ['select', 'create', 'update', 'delete']
			},
			/*
			{
				name: 'productChild',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'productRelateFilter',
				actions: ['select', 'create', 'delete']
			},
			{
				name: 'productSku',
				actions: ['select', 'import', 'export']
			},
			{
				name: 'brand',
				actions: ['select', 'create', 'update', 'delete']
			},*/

		]
	},

	//系列商品
	{
		name: 'seriesProduct',
		code : 'home-seriesProduct',
		children: [
			{
				name: 'seriesProductGroup',
				actions: ['select', 'create', 'update', 'delete']
			}
		]
	},

	//ERP產品管理
	{
		name: 'erpProduct',
		code : 'home-erpProduct',
		children: [
			{
				name: 'exProduct',
				actions: ['select', 'create', 'update', 'delete', 'import', 'export']
			},
			{
				name: 'erpProduct',
				actions: ['select']
			}
		]
	},


	//訂單管理模組
	{
		name: 'order',
		code : 'home-order',
		children: [
			{
				name: 'order',
				actions: ['select', 'create', 'update', 'invoice_create', 'import', 'export']
			},
			/*
			{
				name: 'exorder',
				actions: ['select', 'export']
			},
			{
				name: 'option-order_return_reason',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'option-order_cancel_reason',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'payment',
				actions: ['select', 'update']
			},
			{
				name: 'shippingCompany',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'logistic',
				actions: ['select', 'create', 'update']
			}*/

		]
	},


	//金流管理模組
	{
		name: 'payment',
		code : 'home-payment',
		children: [
			{
				name: 'payment',
				actions: ['select', 'update']
			},
		]
	},

	//物流管理模組
	{
		name: 'logistic',
		code : 'home-logistic',
		children: [
			{
				name: 'holiday_shipping_date',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'orderLogistic',
				actions: ['select']
			},
			{
				name: 'tcatCall',
				actions: ['select']
			}
		]
	},

	//文章管理
	{
		name: 'content',
		code : 'home-content',
		children: [
			{
				name: 'news',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'reviewCategory',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'review',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'blogCategory',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'blog',
				actions: ['select', 'create', 'update', 'delete']
			},

			{
				name: 'introduction',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'activity',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'media_link',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'diary',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recipe',
				actions: ['select', 'create', 'update', 'delete']
			},
		]
	},



	//門市
	{
		name: 'store',
		code : 'home-store',
		children: [
			{
				name: 'storeCategory',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'store',
				actions: ['select', 'create', 'update', 'delete']
			},
		]
	},


	//客服
	{
		name: 'service',
		code : 'home-customerService',
		children: [
			{
				name: 'sms',
				actions: ['select']
			},
			/*
			{
				name: 'mail',
				actions: ['select']
			},
			{
				name: 'option-contact_service_category',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'contact',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'qaCategory',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'qa',
				actions: ['select', 'create', 'update', 'delete']
			},*/
		]
	},


	//說明管理
	{
		name: 'page',
		code : 'home-fixedPage',
		children: [
			{
				name: 'fixedPage',
				actions: ['select', 'create', 'update', 'delete']
			},
		]
	},


	//廣告
	{
		name: 'recommend',
		code : 'home-recommend',
		children: [
		/*
			{
				name: 'parameter-home',
				actions: ['select', 'update']
			},
			{
				name: 'recommend-home_cover',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-home_header',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-home_top',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-star_banner',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-home_bottom',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-special',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-footer',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-marquee',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-topic',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-video_product',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'banner',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'homeLink',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'keywordLink',
				actions: ['select', 'create', 'update', 'delete']
			},
			*/

			/*
			{
				name: 'footer',
				actions: ['select', 'create', 'update', 'delete']
			},*/

			{
				name: 'live',
				actions: ['select', 'create', 'update', 'delete']
			},
			/*
			{
				name: 'recommend-left_cover',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-right_cover',
				actions: ['select', 'create', 'update', 'delete']
			},

			{
				name: 'recommend-star_product',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'recommend-classic_product',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'newProduct',
				actions: ['select', 'create', 'delete']
			},
			*/
			{
				name: 'frame',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'frameProduct',
				actions: ['select', 'create', 'delete']
			},
			/*
			{
				name: 'recommend-discount_event_menu_banner',
				actions: ['select', 'create', 'update', 'delete']
			},*/
			{
				name: 'product_home',
				actions: ['select', 'update']
			},
			{
				name: 'banner_home_top',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'banner_product-home',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'banner_subscription',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'banner_purchase',
				actions: ['select', 'create', 'update', 'delete']
			},
		]
	},




	//會員
	{
		name: 'member',
		code: 'home-member',
		children: [
			{
				name: 'memberQuickSearch',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'option-member_occupation',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'member',
				actions: ['select', 'create', 'update', 'delete', 'update_basic', 'update_phone']
			},
			{
				name: 'tag-member',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'tag-crm',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'level-general',
				actions: ['select', 'create', 'update', 'delete']
			},

			/*
			{
				name: 'level-dollbao',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'zyo_level',
				actions: ['select', 'update']
			},

			{
				name: 'epaper',
				actions: ['select', 'create', 'delete', 'export']
			},
			{
				name: 'deliveryNotice',
				actions: ['select', 'delete']
			},
			{
				name: 'consumption',
				actions: ['select', 'export']
			},*/




		]
	},


	//會員帳戶發放
	{
		name: 'account_transfer',
		code: 'home-accountTransfer',
		children: [
			{
				name: 'shopcashTransfer',
				actions: ['select', 'create']
			},
			{
				name: 'dsystexTransfer',
				actions: ['select', 'create']
			},
			{
				name: 'couponTransfer',
				actions: ['select', 'create', 'delete']
			},
			{
				name: 'shippingCouponTransfer',
				actions: ['select', 'create', 'delete']
			},

			{
				name: 'eticketTransfer',
				actions: ['select', 'create', 'delete']
			},
			{
				name: 'bottleTransfer',
				actions: ['select', 'create']
			},
			{
				name: 'stickerTransfer',
				actions: ['select', 'create']
			}
		]
	},

	//帳戶 會員優惠
	{
		name: 'account',
		code: 'home-account',
		children: [
			/*
			{
				name: 'account',
				actions: ['select']
			},


			{
				name: 'dollbao',
				actions: ['select', 'create', 'update', 'delete']
			},*/
			{
				name: 'shopcash',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				// 紅利點數兌換設定
				name: 'shopcashQualifyRule',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'coupon',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'eticket',
				actions: ['select']
			},
			{
				// 電子票券訂單
				name: 'couponOrderList',
				actions: ['select']
			},
			{
				// 儲值金加值訂單
				name: 'dsystexOrderList',
				actions: ['select']
			},
			{
				name: 'couponTransferImport',
				actions: ['select', 'create', 'delete','import']
			}
		]
	},





	//系統管理
	{
		name: 'admin',
		code : 'home-admin',
		children: [
			{
				name: 'log',
				actions: ['select']
			},
			{
				name: 'admin',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'permission',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'parameter-all',
				actions: ['select', 'update']
			},
		]
	},









	//公司管理
	/*
	{
		name: 'about',
		code : 'home-about',
		children: [
			{
				name: 'aboutPage',
				actions: ['select', 'create', 'update', 'delete']
			},
			{
				name: 'media',
				actions: ['select', 'create', 'update', 'delete']
			},
		]
	},*/












];


interface ChainObject<T> {
    [key: string]: T | ChainObject<T>;
}
type PermissionCodeChain = ChainObject<any>;

export const PermissionCode: PermissionCodeChain = {};

Permission.map(parent => {
    const pName = parent.code.replace(/-([a-zA-Z])/g, (m,p) => p.toUpperCase())
    if (!(pName in PermissionCode)) {
        PermissionCode[pName] = {
            name: pName,
            code: parent.code,
        };
    }

    parent.children.map(child => {
        const cName = child.name.replace(/-([a-zA-Z])/g, (m,p) => p.toUpperCase())
        PermissionCode[pName][cName] = {
            name:    cName,
            code:    `${parent.code}-${child.name}`,
            list:    `${parent.code}-${child.name}List`,
            select:  `${parent.code}-${child.name}@select`,
            create:  `${parent.code}-${child.name}@create`,
            update:  `${parent.code}-${child.name}@update`,
            delete:  `${parent.code}-${child.name}@delete`,
        };
    })
});
