import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Menus } from '../models/menu.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
    shop = {
        id: localStorage.getItem('shop_id'),
        name: localStorage.getItem('shop_name'),
    };

    defaultHidden = true;

    menus: NbMenuItem[] = JSON.parse(JSON.stringify(Menus));

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private httpService: HttpService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.showMenu();
    }

    //顯示menu (依照權限)
    showMenu() {
        let url = `${environment.APIUrl}/api/lab-shop/admin/permissions`;

        this.httpService.getHttp(url).subscribe((res) => {
            //如果localstorage沒有存目前管理的shop id, 則採用預設
            if (!this.shop.id) {
                this.setDefaultShop(res.shop.id, res.shop.name);
            }

            //設定那些menu要顯示
            //
            this.menus.map((menu) => {
                //多語系
                this.translate
                    .get('permission.' + menu.title)
                    .subscribe((text) => {
                        menu.title = text;
                    });
                //menu.title = this.translate.instant('permission.'+menu.title);
                res.items.forEach((item) => {
                    switch (menu.data) {
                        //永久隱藏的目錄
                        case 'home-shop':
                            menu.hidden = true;
                            break;

                        //權限開放的目錄
                        default:
                            //顯示
                            if (res.super_admin == 1 || menu.data == item) {
                                menu.hidden = false;
                            }
                            break;
                    }
                });

                //檢查children
                if (menu.children) {
                    menu.children.map((child) => {
                        //多語系
                        this.translate
                            .get('permission.' + child.title)
                            .subscribe((text) => {
                                child.title = text;
                            });
                        //child.title = this.translate.instant('permission.'+child.title);

                        res.items.forEach((item) => {
                            switch (child.data) {
                                //永久隱藏的目錄
                                //case "home-discount-productDiscountGroup":
                                case 'home-discount-productDiscountGroup-productDiscountList':
                                case 'home-about-mediaCategory-mediaList':
                                case 'home-account-dollbaoFeedback':
                                case 'home-account-dollbaoTransfer':
                                case 'home-account-shopcashTransfer':
                                case "home-account-couponTransfer":
                                case 'home-account-accountList':
                                    //case "home-admin-adminGroup":
                                    child.hidden = true;
                                    break;

                                //權限開放的目錄
                                default:
                                    if (
                                        res.super_admin == 1 ||
                                        child.data == item
                                    ) {
                                        child.hidden = false;
                                    }
                                    break;
                            }
                        });
                    });
                }
            });

            //menu店家名稱顯示
            /*
                let shopadmin = this.translate.instant('permission.'+this.menus[1].title);
  				this.menus[1].title = `${this.shop.name}${shopadmin}`;
*/
            //console.log(this.menus);
        });
    }

    //取得目前管理的店家ID與名稱
    setDefaultShop(shop_id, shop_name) {
        if (!this.shop.id) {
            localStorage.setItem('shop_id', shop_id);
            localStorage.setItem('shop_name', shop_name);
            this.shop.id = localStorage.getItem('shop_id');
            this.shop.name = localStorage.getItem('shop_name');
        }
    }
}
